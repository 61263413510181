import Rails from 'rails-ujs';
import Turbolinks from 'turbolinks';
import 'babel-polyfill';

import nav from '../components/nav';
import banner from '../components/banner';
import search from '../components/search';
import images from '../components/images';
import checkout from '../components/checkout';

import '../styles/main/main.scss';

import '../images/logo.png';

Rails.start();
Turbolinks.start();

document.addEventListener('turbolinks:load', () => {
  nav();
  banner();
  search();
  images();
  checkout();
});
