export default () => {
  const trigger = document.querySelector('.header__nav-trigger');
  const nav = document.querySelector('.header__nav');
  const navOpen = document.querySelector('.nav-open');
  const navClose = document.querySelector('.nav-close');
  const mainBody = document.querySelector('body');
  const header = document.querySelector('.header');
  const hiddenStyle = 'header__icon--hidden';
  const navHiddenStyle = 'header__nav--visible';
  const fixedStyle = 'fixed';

  let currentState = 'closed';

  trigger.addEventListener('click', e => {
    e.preventDefault();

    if (currentState === 'closed') {
      currentState = 'open';
      navOpen.classList.add(hiddenStyle);
      navClose.classList.remove(hiddenStyle);
      nav.classList.add(navHiddenStyle);
      mainBody.classList.add(fixedStyle);
      header.classList.add(fixedStyle);
    } else {
      currentState = 'closed';
      navOpen.classList.remove(hiddenStyle);
      navClose.classList.add(hiddenStyle);
      nav.classList.remove(navHiddenStyle);
      mainBody.classList.remove(fixedStyle);
      header.classList.remove(fixedStyle);
    }
  });
};
