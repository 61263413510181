export default () => {
  const search = document.getElementById('search');
  const showSearchForm = document.getElementById('search-show');
  const closeSearchForm = document.getElementById('search-hide');

  showSearchForm.addEventListener('click', e => {
    e.preventDefault();
    search.classList.add('search--visible');
  });

  closeSearchForm.addEventListener('click', e => {
    e.preventDefault();
    search.classList.remove('search--visible');
  });
};
