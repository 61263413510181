import Flickity from 'flickity';

export default () => {
  if (document.querySelector('.banner')) {
    const carousel = document.querySelector('.banner');
    const flkty = new Flickity(carousel, {
      imagesLoaded: true,
      percentPosition: false,
      autoPlay: true,
      resize: true,
      adaptiveHeight: true
    });
  }
};
