export default () => {
  if (document.querySelector('.product__details--images')) {
    const images = document
      .querySelector('.product__details--images')
      .querySelectorAll('img');
    const mainImage = document.querySelector('.product__image');
    console.log(images);

    for (let image of images) {
      image.addEventListener('click', e => {
        console.log(image === e.target);
        mainImage.innerHTML = `<img src="${image.src}" />`;
      });
    }
  }
};
